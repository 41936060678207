import React, { useState } from "react";
import { useRefresh } from "react-admin";
import useStyles from "./style";
import classnames from "classnames";
import {
  Dropdown,
  DatePicker,
  Button,
} from "@my-scoot/component-library-legacy";
import { handleInputDateFormat } from "utils/Utils";
import { useNotifications } from "utils/hooks";
import GetAppIcon from "@material-ui/icons/GetApp";
import dataProvider from "./../../../../../data/dataProvider";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { datesValidation, getProcessedValues } from "../helpers";
import { is_empty } from "utils/validations";
import { REPORT_VALUES, report_sections } from "../../constants";
import { api } from "data";

const GenerateReport = (props) => {
  const { title } = props;
  const [selectedSection, setSelectedSection] = useState(
    REPORT_VALUES.USER_PAYMENT
  );
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const showDateFilters = report_sections[selectedSection].hasDateFilters;

  const refresh = useRefresh();
  const isDesktop = useDesktopMediaQuery();
  const css = useStyles(isDesktop);

  const { pushNotification } = useNotifications();
  const dateFormat = handleInputDateFormat();
  const tildeDesktop = isDesktop && <span>~</span>;

  const handleClick = async () => {
    if (showDateFilters) {
      try {
        // Validate Entered Dates
        if (is_empty(fromDate) || is_empty(toDate)) {
          const bothEmptyText = "Start Date and End Date cannot be empty";
          const startEmptyText = "Start Date cannot be empty";
          const endEmptyText = "End Date cannot be empty";

          let errorText = "";

          if (is_empty(fromDate) && is_empty(toDate)) errorText = bothEmptyText;
          if (is_empty(fromDate) && !is_empty(toDate))
            errorText = startEmptyText;
          if (!is_empty(fromDate) && is_empty(toDate)) errorText = endEmptyText;

          return pushNotification(errorText, {
            variant: "outlined",
            color: "coral_red",
          });
        }

        datesValidation(fromDate, toDate);
      } catch (err) {
        console.log(err);
        return pushNotification("End date can not be less than start date", {
          variant: "outlined",
          color: "coral_red",
        });
      } // catch block ends
    } // traffic filter if ends

    const query = getProcessedValues(selectedSection, fromDate, toDate);
    try {
      const api_status = await dataProvider.custom_request(
        api.upload_report,
        "POST",
        query
      );
      if (api_status.status === 200) refresh();
    } catch (err) {
      console.log("records handleClick", err);
    }
  };

  return (
    <div className={css.container}>
      <Dropdown
        options={report_sections}
        value={selectedSection}
        handleChange={setSelectedSection}
        placeholder="Search By"
        size="medium"
        dropdownLabel={{
          label: "Report Type",
          size: "small",
          weight: "semi-bold",
        }}
        fullWidth={!isDesktop}
        minWidth="235px"
      />
      {showDateFilters && (
        <>
          {tildeDesktop}
          <div className={classnames(css.dateWrap, css.startDateWrap)}>
            <div className={css.dataLabel}>
              Start Date<sup>*</sup>
            </div>
            <DatePicker
              value={fromDate}
              onChange={setFromDate}
              placeholder={dateFormat}
              dateFormat={dateFormat}
            />
          </div>
          {tildeDesktop}
          <div className={classnames(css.dateWrap, css.endDateWrap)}>
            <div className={css.dataLabel}>
              End Date<sup>*</sup>
            </div>
            <DatePicker
              value={toDate}
              onChange={setToDate}
              placeholder={dateFormat}
              dateFormat={dateFormat}
            />
          </div>
        </>
      )}

      <Button onClick={handleClick} color="primary" fullWidth={!isDesktop}>
        <GetAppIcon className={css.mr8} />
        {title}
      </Button>
    </div>
  );
};

export default GenerateReport;
