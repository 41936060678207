import React, { Suspense } from "react";
const EditSalePageTitleModal = React.lazy(() =>
  import("./EditSalePageTitleModal")
);

export const LazyLoadedEditSalePageTitleModal = (props) => {
  if (!props?.open) return null;
  return (
    <Suspense fallback={null}>
      <EditSalePageTitleModal {...props} />
    </Suspense>
  );
};
