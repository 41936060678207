import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    pageTitle: {
      fontFamily: "Helvetica Neue",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "0.04em",
      color: "#272522",
      marginBottom: "24px",
    },
    container: {
      maxWidth: "100vw",
    },
    mobActionTitle: {
      fontFamily: "Helvetica Neue",
      fontSize: "14px",
      lineHeight: "17px",
      color: "rgba(0, 0, 0, 0.85)",
      fontWeight: "500",
    },
    mobPageTitle: {
      fontFamily: "Helvetica Neue",
      fontWeight: "700",
      fontSize: "16px",
      color: "#272522",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
