import React from "react";
import classnames from "classnames";
import { Chip } from "@my-scoot/component-library-legacy";

import { useStyles } from "./styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { Button } from "@my-scoot/component-library-legacy";
import { REPORT_STATUS, getRecordStatusValues } from "../../constants";
import Tooltip from "ui/modules/Tooltip";

export const ActionField = ({ record, handleClick }) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles(isDesktop);
  const { status } = record;
  const { color, tooltip, label } = getRecordStatusValues(record) || {};

  if (!status) return null;

  return status === REPORT_STATUS.COMPLETED ? (
    <Button
      color="link"
      variant="text"
      disableTouchRipple={true}
      className={classes.linkButton}
      onClick={() => handleClick({ record, source: "response" })}
    >
      <GetAppIcon />
      Download
    </Button>
  ) : (
    <div>
      <Chip
        chipClassName={classnames(
          classes.status_chip,
          classes[`status__${color}`]
        )}
        labelClassName={classes.status_chip_label}
        label={label}
      />
      <Tooltip text={tooltip} />
    </div>
  );
};
