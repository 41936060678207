import { is_empty } from "utils/validations";
import moment from "moment";
import { report_sections, REPORT_SOURCE } from "../constants";

export const datesValidation = (start_date, end_date) => {
  if (
    !is_empty(start_date) &&
    !is_empty(end_date) &&
    moment(start_date, "YYYY-MM-DD").isAfter(moment(end_date, "YYYY-MM-DD"))
  ) {
    throw new Error({ end_date: "Invalid" });
  }
};

export const getProcessedValues = (selectedSection, fromDate, toDate) => {
  let section = report_sections[selectedSection];
  let query = {
    requested_report_type: section.request_type,
    report_source: REPORT_SOURCE.WORKFLOW_REPORTS,
  };
  if (section.hasDateFilters) {
    query.report_start_date = moment(fromDate).format("YYYY-MM-DD");
  }
  if (section.hasDateFilters) {
    query.report_end_date = moment(toDate).format("YYYY-MM-DD");
  }
  return query;
};
