import React from "react";
import { getS3SignedUrl } from "../../../utils/Utils";
import ExlyTable from "../../../common/Components/ExlyTable";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { getTableConfig, REPORTS_LEARN_MORE_HREFS } from "./constants";
import { ActionField } from "./components/actionField/ActionFields";
import GenerateReport from "./components/generateReport/GenerateReport.jsx";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { form_field_labels } from "./constants";
import EmptyListIcon from "assets/images/reports_empty.svg";
import useStyles from "./styles";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";

const Reports = (props) => {
  const isDesktop = useDesktopMediaQuery();
  const css = useStyles(isDesktop);

  const DownloadField = async ({ record, source = "report_url" }) => {
    const key = record?.[source]?.split(".com/")[1];
    const url = await getS3SignedUrl(key);
    if (url) window.open(url);
  };

  return (
    <div className={`component-wrapper ${css.container}`}>
      <ExlyTable
        ra_props={{
          ...props,
        }}
        columnConfig={getTableConfig()}
        layoutProps={{
          paddingDesktop: "0",
          paddingBottom: "68px",
          layoutMobileMargin: "0",
          layoutDesktopMargin: "0",
          paddingMobile: "0",
          noMobileBoxShadow: true,
          learnMoreHref: REPORTS_LEARN_MORE_HREFS.REPORTS,
          secondaryComponent: isDesktop ? (
            <LearnMoreCta href={REPORTS_LEARN_MORE_HREFS.REPORTS} />
          ) : null,
          description: <GenerateReport title={form_field_labels.title} />,
        }}
        fieldsLeftPadded
        borderedFields
        drawerFieldsBordered
        tableTitle={form_field_labels.title}
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        primaryKey="id"
        desktopCustomPrimaryAction={{
          renderCustomAction: (record) => (
            <ActionField record={record} handleClick={DownloadField} />
          ),
        }}
        renderSecondaryAction={() => (
          <div className={css.mobActionTitle}>Action:</div>
        )}
        customPrimaryAction={{
          renderCustomAction: (record) => (
            <>
              <ActionField record={record} handleClick={DownloadField} />
            </>
          ),
        }}
        primaryColumnProps={{
          title: "Action",
          align: "left",
        }}
        customEmptyState={
          <ExlyEmptyState
            title="There's no data yet"
            alt="There's no data yet"
            description="Once you start receiving bookings, you can generate & download all the leads, customer payments & payouts data from here."
            imgSrc={EmptyListIcon}
            secondaryCtaProps={{
              variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
              learnMoreHref: REPORTS_LEARN_MORE_HREFS.REPORTS,
            }}
          />
        }
      />
    </div>
  );
};

export default withComponentLibraryTheme(Reports);
