import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  () => ({
    container: {
      fontFamily: "Helvetica Neue",
      display: "flex",
      alignItems: "end",
      flexWrap: "wrap",
      "& > div": {
        marginBottom: (isDesktop) => !isDesktop && "20px",
      },
      "& > div:last-child": {
        marginLeft: "24px",
      },
      "& > span": {
        margin: "8px",
      },
      "& > div > button": {
        paddingRight: "24px",
      },
      "& input::placeholder": {
        textTransform: "uppercase",
      },
      "& > div:first-child > div:first-child": {
        fontFamily: "Helvetica Neue",
      },
      // Mobile
      "& > div:first-child, & > div:last-child": {
        width: (isDesktop) => !isDesktop && "100%",
        minWidth: (isDesktop) => !isDesktop && "100%",
        marginLeft: (isDesktop) => !isDesktop && "0",
      },
    },
    dateWrap: {
      width: (isDesktop) => !isDesktop && "calc(50% - 7px)",
      minWidth: (isDesktop) => !isDesktop && "calc(50% - 7px)",
      zIndex: 3,
    },
    startDateWrap: {
      marginRight: (isDesktop) => !isDesktop && "7px",
    },
    endDateWrap: {
      marginLeft: (isDesktop) => !isDesktop && "7px",
    },
    dataLabel: {
      fontFamily: "Helvetica Neue",
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 500,
      marginBottom: "9px",
    },
    mr8: {
      marginRight: "8px",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
