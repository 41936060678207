import React, { Suspense } from "react";
const DeleteSalePageModal = React.lazy(() => import("./DeleteSalePageModal"));

export const LazyLoadedDeleteSalePageModal = (props) => {
  if (!props.open) return null;
  return (
    <Suspense fallback={null}>
      <DeleteSalePageModal {...props} />
    </Suspense>
  );
};
