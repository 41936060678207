import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";
import { IExlyEmptyState } from "features/Common/modules/ExlyEmptyState/ExlyEmptyState.interfaces";
import React, { FC } from "react";

const EmptyState: FC<IExlyEmptyState> = (props) => {
  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.SALES_PAGES,
    // @ts-ignore
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  return (
    <ExlyEmptyState
      {...props}
      isLoading={isFeatureFlagLoading}
      playerProps={{
        playing: !isFeatureFlagLoading && !isFeatureVisited, // autoplay the knowledge base video on the first visit to this feature
      }}
    />
  );
};

export default EmptyState;
