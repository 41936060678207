import React, { Suspense } from "react";
const ShareSalePageModal = React.lazy(() => import("./ShareSalePageModal"));

export const LazyLoadedShareSalePageModal = (props) => {
  if (!props.open) return null;
  return (
    <Suspense fallback={null}>
      <ShareSalePageModal {...props} />
    </Suspense>
  );
};
