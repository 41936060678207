import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  linkButton: {
    color: "#493AB1",
    padding: (isDesktop) => (isDesktop ? "0" : "0 8px 0 0"),
    background: "transparent !important",
    height: "28px",
    "& svg": {
      marginRight: "8px",
      marginLeft: (isDesktop) => isDesktop && "-12px",
    },
  },
  status_chip_label: {
    color: `${theme?.palette?.basic?.white} !important`,
  },
  status__warning: {
    // Expired
    background: `${theme?.palette?.butterscotch_yellow?.main} !important`,
    "&:hover": {
      background: `${theme?.palette?.butterscotch_yellow?.main} !important`,
    },
  },
  status__danger: {
    // Expired
    background: `${theme?.palette?.basic?.danger_red} !important`,
    "&:hover": {
      background: `${theme?.palette?.basic?.danger_red} !important`,
    },
  },
}));
