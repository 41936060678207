import React, { Suspense } from "react";
const AddSalesPageModal = React.lazy(() => import("./AddSalesPageModal"));

export const LazyLoadedAddSalesPageModal = (props) => {
  if (!props.open) return null;
  return (
    <Suspense fallback={null}>
      <AddSalesPageModal {...props} />
    </Suspense>
  );
};
